<template>
    <div id="summary">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body" v-if="getSelectedCompetition !== null">
                        <div class="float-right">
                            <button type="button" class="btn btn-primary btn-sm btn-rounded" @click="$router.push({ name: 'CompetitionDetailWrapper', query: { type: computeUrlType() } })">{{$t('summary.tornaAllaBancaDati')}}</button>
                        </div>
                        <h3 class="card-title font-size-15 mt-0">{{getSelectedCompetition.ente.ente}}</h3>
                        <p class="card-text">
                            {{getSelectedCompetition.concorso}}<br>
                            {{getSelectedCompetition.anno}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="sfida" role="tabpanel">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 text-center mb-5">
                            <h5>{{$t('summary.risultatiProva')}}</h5>
                            <p>{{$t('summary.punteggioTotale')}} <strong style="font-size:22px">{{totalScore}}</strong></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="card noti border mt-4 mt-lg-0 mb-0">
                                <div
                                    :class="['card-body', correctAnswers > 0 ? 'hoverable cursorPointer' : undefined].filter(x => x).join(' ')"
                                    @click="correctAnswers > 0 ? watchTest(undefined, 'CORRETTE') : () => {}"
                                >
                                    <div class="text-center">
                                        <i class="mdi mdi-file-document-box-check-outline font-size-24"></i>
                                        <h6 class="alert-heading">{{$t('summary.rispEsatte')}}</h6>
                                        <p class="text-success font-size-20">{{correctAnswers}}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card border mb-0">
                                <div
                                    :class="['card-body', noAnswers > 0 ? 'hoverable cursorPointer' : undefined].filter(x => x).join(' ')"
                                    @click="noAnswers > 0 ? watchTest(undefined, 'NON_RISPOSTE') : () => {}"
                                >
                                    <div class="text-center">
                                        <i class="mdi mdi-cancel font-size-24"></i>
                                        <h6 class="alert-heading">{{$t('summary.noRisposte')}}</h6>
                                        <p class="text-muted font-size-20">{{noAnswers}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card noti border mt-4 mt-lg-0 mb-0">
                                <div
                                    :class="['card-body', wrongAnswers > 0 ? 'hoverable cursorPointer' : undefined].filter(x => x).join(' ')"
                                    @click="wrongAnswers > 0 ? watchTest(undefined, 'ERRATE') : () => {}"
                                >

                                    <div class="text-center">
                                        <i class="mdi mdi-file-document-box-remove-outline font-size-24"></i>
                                        <h6 class="alert-heading">{{$t('summary.rispErrate')}}</h6>
                                        <p class="text-danger font-size-20">{{wrongAnswers}}</p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- end row -->
                    <div class="row">
                        <div class="col-12 text-center">
                            <button type="button" @click="watchTest()" class="btn btn-rounded btn-lg btn-success mt-3 mb-3"><strong style="color:white">{{$t('summary.vediProva')}}</strong></button>
                        </div>
                    </div>

                </div>
            </div>


            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5>{{$t('summary.risultatiPerSingolaMateria')}}</h5>
                            <div class="table-responsive">
                                <table class="table table-centered mb-0">
                                    <thead class="thead-light">
                                        <tr class="font-weight-bold">
                                            <th scope="col">{{$t('summary.materia')}}</th>
                                            <th scope="col" class="text-center"><i class="mdi mdi-clipboard-list-outline font-size-20"></i> {{$t('summary.domande')}}</th>
                                            <th scope="col" class="text-center"><i class="mdi mdi-file-document-box-check-outline font-size-20"></i> {{$t('summary.corrette')}}</th>
                                            <th scope="col" class="text-center"><i class="mdi mdi-cancel font-size-20"></i> {{$t('summary.nonRisposte')}}</th>
                                            <th scope="col" class="text-center"><i class="mdi mdi-file-document-box-remove-outline font-size-20"></i> {{$t('summary.errate')}}</th>
                                            <th scope="col" class="text-center"> </th>
                                        </tr>
                                    </thead>
                                    <tbody class="record_table">
                                        <template v-for="subject in subjects">
                                            <tr class="">
                                                <th>
                                                    <b>{{subject.name}}</b>
                                                </th>
                                                <td class="text-center" >
                                                    {{subject.nQuestions}}
                                                </td>
                                                <!-- Colonne Corrette, Non Risposte, Errate -->
                                                <td
                                                    v-for="([count, filter]) in [[subject.correctAnswers, 'CORRETTE'], [subject.noAnswers, 'NON_RISPOSTE'], [subject.wrongAnswers, 'ERRATE']]"
                                                    :class="['text-center', count > 0 ? 'cursorPointer hoverable' : undefined].filter(x => x).join(' ')"
                                                    @click="count > 0 ? watchTest(subject.idSubject, filter) : () => {}"
                                                >
                                                    {{count}}
                                                </td>
                                                <!-- / Colonne Corrette, Non Risposte, Errate -->
                                                <td class="text-center" >
                                                    <button type="button" @click="watchTest(subject.idSubject)" class="btn btn-rounded btn-sm btn-primary">{{$t('summary.vediProvaMateria')}}</button>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid" v-if="showButtonNuovaProva">
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" @click="setupSimulation()" class="btn btn-rounded btn-lg btn-success mt-3 mb-3"><strong style="color:white">{{$t('summary.nuovaProva')}}</strong></button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal error -->
        <div id="modalErrorSummary" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorSummaryLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorSummaryLabel">{{$t('summary.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-error-summary" class="modal-body">
                        <p id="message-body-error-summary"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal error -->

        <!-- Modal error blocker -->
        <div id="modalErrorSummaryBlocker" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorSummaryBlockerLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorSummaryBlockerLabel">{{$t('summary.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-error-summary-blocker" class="modal-body">
                        <p id="message-body-error-summary-blocker"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal error blocker -->

        <!-- Modal limit quiz -->
        <div id="modalLimitQuiz" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLimitQuizLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalLimitQuizLabel">{{$t('competitionDetail.modale.limitQuiz.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-limit" class="modal-body">
                        <p id="message-body-limit">{{$t('competitionDetail.modale.limitQuiz.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.SUBSCRIPTION })">{{$t('competitionDetail.modale.limitQuiz.passaPremium')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal limit quiz -->

    </div>
</template>

<script>
import $ from "jquery";
import _ from "lodash";
import EsercitazioneManager from "@/lib/ConcorsandoWasm/EsercitazioneManager";
import {competitionDetailTabsEnums, routesEnums} from "@/helpers/enums";
import Worker from "@/lib/ConcorsandoWasm/WrappedWorker";
import {mapActions, mapGetters} from "vuex";
import {MODULES} from "@/store/modules-enum";
import {COMPETITION_GETTERS} from "@/store/competition/getters-enum";
import {AUTH_GETTERS} from "@/store/auth/getters-enum";
import {COMPETITION_ACTIONS} from "@/store/competition/actions-enum";

const worker = Worker.instance
const esercitazioneManager = EsercitazioneManager.instance

export default {
    name: "Summary",
    data () {
        return {
            scoreConfig: {},
            summary: [],
            idCompetition: "",
            subjects: [],
            currentSummaryMeta: {},
            showButtonNuovaProva: false,
        }
    },
    mounted() {
        this.backToTop()
        this.verifyUUID()
    },
    computed: {
        ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.GET_SELECTED_COMPETITIONS]),
        ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE]),
        correctAnswers () {
            return _.filter(this.summary, (s) => s.isCorrectAnswer !== undefined && s.isCorrectAnswer).length
        },
        wrongAnswers () {
            return _.filter(this.summary, (s) => s.isCorrectAnswer !== undefined && !s.isCorrectAnswer).length
        },
        noAnswers () {
            return (this.summary.length - (this.correctAnswers + this.wrongAnswers))
        },
        totalScore () {
            const scoreCorrectAnswers = this.correctAnswers * this.scoreConfig.punteggio_risposte_corrette
            const scoreWrongAnswers = this.wrongAnswers * this.scoreConfig.punteggio_risposte_sbagliate
            const scoreNoAnswers = this.noAnswers * this.scoreConfig.punteggio_non_risposte

            const totalScore = (scoreCorrectAnswers + scoreWrongAnswers + scoreNoAnswers).toFixed(2)
            if (Number.isNaN(totalScore) || totalScore === "0.00") {
                return 0
            } else {
                return totalScore
            }
        }
    },
    methods: {
        ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_SIMULATION]),
        verifyUUID() {
            if (this.$route.params.uuid !== undefined && this.$route.params.uuid !== "") {
                this.getExerciseMeta()
            } else {
                this.$router.push({ name: routesEnums.HOME })
            }
        },
        showModalErrorBlocker(message) {
            const context = this
            this.$emit('is-components-ready', 'summary', true)
            $('#modalErrorSummaryBlocker').modal({backdrop: 'static', keyboard: false})
            $('#message-body-error-summary-blocker').html(context.$t(message))
        },
        showModalError(message) {
            const context = this
            this.$emit('is-components-ready', 'summary', true)
            $('#modalErrorSummary').modal('show')
            $('#message-body-error-summary').html(context.$t(message))
        },
        getExerciseMeta () {
            esercitazioneManager.getEsercitazioneMeta()
                .then((res) => {
                    this.currentSummaryMeta = res
                    this.setShowButtonNuovaProva();
                    if (res.uuid_esercitazione === this.$route.params.uuid) {
                        this.scoreConfig = res.config_punteggio
                        this.idCompetition = res.id_concorso
                        this.summary = res.riepilogo
                        this.getSubjects()
                    } else {
                        this.$router.push({ name: routesEnums.HOME })
                    }
                })
                .catch(() => {
                    this.showModalErrorBlocker('summary.modale.errore.esercitazioneManager')
                })
        },
        getSubjects () {
            worker.getMaterie(this.idCompetition)
                .then((res) => {
                    this.computeSubjectsForTest(res)
                    this.$emit('is-components-ready', 'summary', true)
                })
                .catch(() => {
                    this.showModalError('summary.modale.errore.materie')
                })
        },
        computeSubjectsForTest (subjects) {
            const groupedSubjects = _.groupBy(this.summary, (s) => s.id_materia)
            subjects.forEach(s => {
                if (groupedSubjects[s.id_materia] !== undefined) {
                    const correctAnswers = _.filter(groupedSubjects[s.id_materia], (gS) => gS.isCorrectAnswer !== undefined && gS.isCorrectAnswer).length
                    const wrongAnswers = _.filter(groupedSubjects[s.id_materia], (gS) => gS.isCorrectAnswer !== undefined && !gS.isCorrectAnswer).length
                    const noAnswers = (groupedSubjects[s.id_materia].length - (correctAnswers + wrongAnswers))
                    this.subjects.push({
                        nQuestions: groupedSubjects[s.id_materia].length,
                        correctAnswers: correctAnswers,
                        wrongAnswers: wrongAnswers,
                        noAnswers: noAnswers,
                        idSubject: s.id_materia,
                        name: s.materia_uff
                    })
                }
            })
        },
        /**
         *
         * @param {string} [idSubject]
         * @param {"CORRETTE" | "NON_RISPOSTE" | "ERRATE"} [answerStatus]
         */
        watchTest(idSubject, answerStatus) {
            this.$emit('is-components-ready', 'summary', false)
            if (idSubject === null || idSubject === "") {
                this.showModalError('summary.modale.errore.idMateria')
            }
            else if (this.idCompetition === undefined || this.idCompetition === null || this.idCompetition === "") {
                this.showModalError('summary.modale.errore.idConcorso')
            }
            else {
                let moreOptions = idSubject ? {id_materia: idSubject} : {};
                moreOptions = answerStatus !== undefined ? {...moreOptions, answer_status_filter: answerStatus} : moreOptions;
                esercitazioneManager.setupRivediProva({
                    id_concorso: this.idCompetition,
                    ...moreOptions
                })
                    .then((res) => {
                        this.$emit('is-components-ready', 'summary', true)
                        this.$router.push({ name: routesEnums.EXERCISE, params: { uuid: res, type: this.computeUrlType() } })
                    })
                    .catch(() => {
                        this.showModalError('summary.modale.errore.setupRivediProva')
                    })
            }
        },
        computeUrlType () {
            if (this.$route.params.type === competitionDetailTabsEnums.EXERCISE) {
                return competitionDetailTabsEnums.EXERCISE
            } else if (this.$route.params.type === competitionDetailTabsEnums.PERSONALIZED_TRIAL) {
                return competitionDetailTabsEnums.PERSONALIZED_TRIAL
            } else if (this.$route.params.type === competitionDetailTabsEnums.CHALLENGE) {
                return competitionDetailTabsEnums.CHALLENGE
            }
        },
        backToTop () {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        setShowButtonNuovaProva () {
            const idSimulazione = this.currentSummaryMeta.id_simulazione;
            const numeroDomande = this.currentSummaryMeta.n_quiz;
            this.showButtonNuovaProva = ((this.profile.livello >= 2 && this.profile.livello < 502 && (((502 - this.profile.livello) - numeroDomande) >= 0)) || this.profile.livello <= 1) && (idSimulazione != null && idSimulazione !== "")
        },
        /**
         * Simulations
         */
        setupSimulation() {
            const idSimulazione = this.currentSummaryMeta.id_simulazione;
            const numeroDomande = this.currentSummaryMeta.n_quiz;

            if ((this.profile.livello >= 2 && this.profile.livello < 502 && (((502 - this.profile.livello) - numeroDomande) >= 0)) || this.profile.livello <= 1) {
                if (idSimulazione != null && idSimulazione !== "") {
                    this.$emit('is-components-ready', 'summary', false)
                    this.getSimulation({
                        idSimulazione: idSimulazione,
                        attiva: true
                    })
                        .then((res) => {
                            esercitazioneManager.setupSimulazione({
                                id_concorso: res.id_concorso,
                                id_simulazione: res.id_simulazione,
                                durata: res.durata,
                                materie_simulazione: res.materie_simulazione,
                                punteggio_risposte_corrette: res.punteggio_risposte_corrette,
                                punteggio_risposte_sbagliate: res.punteggio_risposte_sbagliate,
                                punteggio_non_risposte: res.punteggio_non_risposte
                            })
                                .then((res) => {
                                    this.$emit('is-components-ready', 'summary', true)
                                    this.$router.push({
                                        name: 'Exercise',
                                        params: {uuid: res, type: competitionDetailTabsEnums.CHALLENGE}
                                    })
                                })
                                .catch(() => {
                                    this.$emit('is-components-ready', 'summary', true)
                                })
                        })
                        .catch(() => {
                            this.showModalError('competitionDetail.modale.errore.simulazione')
                        })
                }
            } else {
                $('#modalLimitQuiz').modal({backdrop: 'static', keyboard: false})
            }
        },
    }
}
</script>

<style scoped>

</style>
